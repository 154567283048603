var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datasource-container"},[(_vm.type === 'OAUTH2' || _vm.type === 'OAUTH2_EMBED' )?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("认证配置 "),_c('a',{on:{"click":_vm.show_sheet}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("身份源ID：")]),_c('span',{style:({
              color: _vm.external_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.external_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("显示名称：")]),_c('span',{style:({
              color: _vm.name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.name || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("scope：")]),_c('span',{style:({
              color: _vm.scope ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.scope || "未填写")+" ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("App ID：")]),_c('span',{style:({
              color: _vm.client_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.client_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("App Secret：")]),_c('span',{style:({
              color: _vm.client_secret ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.client_secret || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("授权URL：")]),_c('span',{style:({
              color: _vm.authorization_base_url ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.authorization_base_url || "未填写")+" ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("访问令牌API：")]),_c('span',{style:({
              color: _vm.token_api_url ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.token_api_url || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("用户信息API：")]),_c('span',{style:({
              color: _vm.user_api_url ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.user_api_url || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("用户ID字段：")]),_c('span',{style:({
              color: _vm.identity_attribute ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.identity_attribute || "未填写")+" ")])])],1)],1)],1):_vm._e(),(_vm.type === 'WECHAT_ENTERPRISE')?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("认证配置 "),_c('a',{on:{"click":_vm.show_sheet}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("身份源ID：")]),_c('span',{style:({
              color: _vm.external_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.external_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("显示名称：")]),_c('span',{style:({
              color: _vm.name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.name || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("使用微信扫码：")]),_c('span',{style:({
              color: _vm.wechat_qrcode ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.wechat_qrcode ? "已开启" : "未开启")+" ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("企业ID：")]),_c('span',{style:({
              color: _vm.corp_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.corp_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("Agent ID：")]),_c('span',{style:({
              color: _vm.agent_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.agent_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("应用的凭证密钥：")]),_c('span',{style:({
              color: _vm.agent_secret ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.agent_secret || "未填写")+" ")])])],1)],1)],1):_vm._e(),(_vm.type === 'CAS' || _vm.type === 'CAS_REST_API')?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("认证配置 "),_c('a',{on:{"click":_vm.show_sheet}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("身份源ID：")]),_c('span',{style:({
              color: _vm.external_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.external_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("显示名称：")]),_c('span',{style:({
              color: _vm.name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.name || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("Base_url：")]),_c('span',{style:({
              color: _vm.base_url ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.base_url || "未填写")+" ")])])],1)],1)],1):_vm._e(),(_vm.type === 'LDAP' || _vm.type === 'FNU_LDAP')?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("认证配置 "),_c('a',{on:{"click":_vm.show_sheet}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("身份源ID：")]),_c('span',{style:({
              color: _vm.external_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.external_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("显示名称：")]),_c('span',{style:({
              color: _vm.name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.name || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("解码方式：")]),_c('span',{style:({
              color: _vm.encoding ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.encoding || "未填写")+" ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("服务地址：")]),_c('span',{style:({
              color: _vm.host ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.host || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("端口号：")]),_c('span',{style:({
              color: _vm.port ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.port || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("启用SSL：")]),_c('span',{style:({
              color: _vm.enable_ssl ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.enable_ssl ? "启用" : "关闭")+" ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("用户名：")]),_c('span',{style:({
              color: _vm.username ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.username || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("密码：")]),_c('span',{style:({
              color: _vm.password ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.password || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("Base_DN：")]),_c('span',{style:({
              color: _vm.base_dn ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.base_dn || "未填写")+" ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("过滤条件：")]),_c('span',{style:({
              color: _vm.search_filter ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.search_filter || "未填写")+" ")])])],1)],1)],1):_vm._e(),(_vm.type === 'NACTA_AUTH')?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("认证配置 "),_c('a',{on:{"click":_vm.show_sheet}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("身份源ID：")]),_c('span',{style:({
              color: _vm.external_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.external_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("显示名称：")]),_c('span',{style:({
              color: _vm.name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.name || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("basic_auth_url：")]),_c('span',{style:({
              color: _vm.basic_auth_url ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.basic_auth_url || "未填写")+" ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("send_sms_url：")]),_c('span',{style:({
              color: _vm.send_sms_url ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.send_sms_url || "未填写")+" ")])]),_c('v-col'),_c('v-col')],1)],1)],1):_vm._e(),(_vm.type === 'S002JWT')?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("认证配置 "),_c('a',{on:{"click":_vm.show_sheet}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("身份源ID：")]),_c('span',{style:({
              color: _vm.external_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.external_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("显示名称：")]),_c('span',{style:({
              color: _vm.name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.name || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("audience：")]),_c('span',{style:({
              color: _vm.audience ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.audience || "未填写")+" ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("signing_algorithm：")]),_c('span',{style:({
              color: _vm.signing_algorithm ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.signing_algorithm || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("verifying_key：")]),_c('span',{style:({
              color: _vm.verifying_key ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.verifying_key || "未填写")+" ")])]),_c('v-col')],1)],1)],1):_vm._e(),(_vm.type === 'DING_TALK')?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("认证配置 "),_c('a',{on:{"click":_vm.show_sheet}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("身份源ID：")]),_c('span',{style:({
              color: _vm.external_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.external_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("显示名称：")]),_c('span',{style:({
              color: _vm.name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.name || "未填写")+" ")])]),_c('v-col')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('span',[_vm._v("App Key：")]),_c('span',{style:({
              color: _vm.app_key ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.app_key || "未填写")+" ")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('span',[_vm._v("App Secret：")]),_c('span',{style:({
              color: _vm.app_secret ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.app_secret || "未填写")+" ")])]),_c('v-col')],1)],1)],1):_vm._e(),(_vm.type === 'SMS')?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("认证配置 "),_c('a',{on:{"click":_vm.show_sheet}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("身份源ID：")]),_c('span',{style:({
              color: _vm.external_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.external_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("显示名称：")]),_c('span',{style:({
              color: _vm.name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.name || "未填写")+" ")])]),_c('v-col')],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("用户名：")]),_c('span',{style:({
              color: _vm.username ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.username || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("密码：")]),_c('span',{style:({
              color: _vm.password ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.password || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("系统提示信息：")]),_c('span',{style:({
              color: _vm.sms_message ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.sms_message || "未填写")+" ")])])],1)],1)],1):_vm._e(),(_vm.type === 'LARK')?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("认证配置 "),_c('a',{on:{"click":_vm.show_sheet}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("身份源ID：")]),_c('span',{style:({
              color: _vm.external_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.external_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("显示名称：")]),_c('span',{style:({
              color: _vm.name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.name || "未填写")+" ")])]),_c('v-col')],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("App ID：")]),_c('span',{style:({
              color: _vm.app_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.app_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("App Secret：")]),_c('span',{style:({
              color: _vm.app_secret ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.app_secret || "未填写")+" ")])]),_c('v-col')],1)],1)],1):_vm._e(),(_vm.type === 'WEBVPN')?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("认证配置 "),_c('a',{on:{"click":_vm.show_sheet}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("身份源ID：")]),_c('span',{style:({
              color: _vm.external_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.external_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("显示名称：")]),_c('span',{style:({
              color: _vm.name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.name || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("认证地址：")]),_c('span',{style:({
              color: _vm.webvpn_auth_url ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.webvpn_auth_url || "未填写")+" ")])])],1)],1)],1):_vm._e(),(
      !(
        _vm.type === 'CAS' ||
        _vm.type === 'CAS_REST_API' ||
        _vm.type === 'LDAP' ||
        _vm.type === 'FNU_LDAP' ||
        _vm.type === 'OAUTH2' ||
        _vm.type === 'OAUTH2_EMBED' ||
        _vm.type === 'HTTP_BASIC'
      )
    )?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("组织同步选项 "),_c('a',{on:{"click":_vm.editDepartment}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("根组织：")]),_c('span',{style:({
              color: _vm.departmentConfig.default_department_name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.departmentConfig.root_department_name || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("合并根组织：")]),_vm._v(" "+_vm._s(_vm.departmentConfig.merge_root_department === "merge" ? "是" : "否"))]),_c('v-col')],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("创建组织：")]),_vm._v(" "+_vm._s(_vm.departmentConfig.create_entity === "create" ? "是" : "否"))]),_c('v-col',[_c('span',[_vm._v("更新组织：")]),_vm._v(" "+_vm._s(_vm.departmentConfig.update_entity === "update" ? "是" : "否"))]),_c('v-col',[_c('span',[_vm._v("过期组织：")]),_vm._v(" "+_vm._s(_vm.departmentConfig.stale_entity === "keep" ? "保留" : "禁用"))])],1)],1)],1):_vm._e(),_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("账户同步选项 "),_c('a',{on:{"click":_vm.editAccount}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("匹配规则： ")]),_vm._l((_vm.accountConfig.matchers),function(item,index){return _c('v-chip',{key:((item.claims) + "_" + index),staticClass:"ma-1",attrs:{"color":"primary","outlined":""}},[_c('v-icon',[_vm._v("mdi-swap-horizontal")]),_vm._v(" "+_vm._s(_vm.match_types[item.matcher] || item.matcher)+" ")],1)})],2),_c('v-col',[_c('span',[_vm._v("白名单规则：")]),_vm._v(" 默认全部放行")]),(
            !(
              _vm.type === 'LDAP' ||
              _vm.type === 'WECHAT_ENTERPRISE' ||
              _vm.type === 'DING_TALK' ||
              _vm.type === 'LARK'
            )
          )?_c('v-col',[_c('span',[_vm._v("根组织：")]),_c('span',{style:({
              color: _vm.departmentConfig.default_department_name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.departmentConfig.default_department_name || "未填写")+" ")])]):_c('v-col')],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("允许同步新用户：")]),_vm._v(" "+_vm._s(_vm.accountConfig.create_entity === "create" ? "是" : "否"))]),_c('v-col',[_c('span',[_vm._v("更新用户属性：")]),_vm._v(" "+_vm._s(_vm.accountConfig.update_entity === "update" ? "是" : "否"))]),_c('v-col',[_c('span',[_vm._v("过期用户：")]),_vm._v(" "+_vm._s(_vm.accountConfig.stale_entity === "keep" ? "保留" : "禁用"))])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("缺省用户组：")]),_c('span',{style:({
              color: _vm.accountConfig.default_group_name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.accountConfig.default_group_name || "未填写")+" ")])]),_c('v-col'),_c('v-col')],1)],1)],1),_c(_vm.sheet_editor,{ref:"child",tag:"Component",attrs:{"type":"edit","allDatas":_vm.allDatas,"departmentTree":_vm.departmentTree}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }